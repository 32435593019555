import { listOrganizations } from '../API/Organizations';
import React, { useEffect } from 'react';
import { useAuth } from 'src/contexts/auth.context';

const globalStateContext = React.createContext(undefined);

export function useGlobalState() {
  const context = React.useContext(globalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
}

export function GlobalStateProvider({ children }) {
  const [state, setState] = React.useState({
    organization: '',
    organizationList: [],
  });

  const { user, isAuthenticated } = useAuth();

  if (isAuthenticated && !state.organization) {
    setState({ ...state, organization: user.organization });
  }

  function getOrganizationName(orgID: string) {
    const org = state.organizationList.find((org) => org.OrganizationID === orgID);
    return org?.OrganizationName;
  }

  function isGlobalAdmin() {
    const orgName = getOrganizationName(user.organization);
    return orgName === 'RareCare';
  }

  useEffect(() => {
    function initGlobalState() {
      if (state.organizationList.length) return;
      listOrganizations().then((res: any) => {
        setState({ ...state, organizationList: res });
      });
    }
    initGlobalState();
  }, [state]);

  return (
    <globalStateContext.Provider value={{ ...state, getOrganizationName, isGlobalAdmin }}>
      {children}
    </globalStateContext.Provider>
  );
}
