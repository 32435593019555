import { adminGetAdminsList } from '../../API/Admins';
import { listOrganizations } from '../../API/Organizations';
import AdminDrawer from '../Admins/AdminDrawer';
import PasswordDrawer from '../Password/PasswordDrawer';
import { ProColumns, ProTable } from '@ant-design/pro-components';
import { Tag } from 'antd';
import React, { useState } from 'react';

const AdminsTable: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [orgs, setOrgs] = useState<any>([]);
  const [adminsList, setAdminsList] = useState([]);

  if (!loaded) {
    adminGetAdminsList().then((adms) => {
      setAdminsList(adms);
      listOrganizations().then((organizations) => {
        setOrgs(organizations);
        setLoaded(true);
      });
    });
  }

  const refresh = () => {
    setLoaded(false);
  };

  const columns: ProColumns<any>[] = [
    {
      title: 'Email',
      render: (item) => {
        const admin = item as any;
        return (
          <>
            <AdminDrawer
              editValues={admin}
              refresh={refresh}
              buttonTitle={admin.email}
              buttonType="edit"
            />
          </>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'given_name',
    },
    {
      title: 'Family Name',
      dataIndex: 'family_name',
    },
    {
      title: 'Organization',
      // dataIndex: 'custom:organization',
      render: (item: any) => {
        let ProvName = 'N/A';
        orgs.forEach((org: any) => {
          if (org.OrganizationID === item['custom:organization']) {
            ProvName = org.OrganizationName;
          }
        });
        return <Tag color={'green'}>{ProvName}</Tag>;
      },
    },
    {
      title: 'Creation Date',
      key: 'creationDate',
      dataIndex: 'UserCreateDate',
      valueType: 'date',
    },
    {
      title: 'Options',
      render: (item) => {
        const admin = item as any;
        return <PasswordDrawer userID={admin.Username} type={'admin'} />;
      },
    },
  ];

  return (
    <ProTable<any, any>
      headerTitle="RareCare Administrators"
      rowKey="key"
      search={false}
      toolBarRender={() => [<AdminDrawer buttonTitle={'New Admin'} refresh={refresh} />]}
      dataSource={adminsList}
      columns={columns}
    />
  );
};

export default AdminsTable;
