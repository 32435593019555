import { listSequencingProviders, newSequencing } from '../../API/Sequencing';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import React, { useState } from 'react';

type Props = {
  refresh: () => void;
  caseID: string;
  buttonTitle: string;
  buttonType?: string;
  editValues?: RareCare.Sequencing;
};

const SequencingDrawer: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [provider, setProvider] = useState('');
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  if (!loaded) {
    listSequencingProviders().then((provs: any) => {
      setProvidersList(provs);
      setLoaded(true);
    });
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = () => {
    if (props.buttonType === 'edit') {
      // const org = props.editValues as RareCare.Organization;
      // org.OrganizationName = form.getFieldValue('OrganizationName');
      // updateOrganization(org).then(() => {
      //   props.refresh();
      //   setOpen(false);
      // });
    } else {
      newSequencing(props.caseID, provider, form.getFieldValue('SequencingKitID')).then(() => {
        props.refresh();
        setOpen(false);
      });
    }
  };

  const renderButton = () => {
    if (props.buttonType === 'edit') {
      return <a onClick={showDrawer}>Edit</a>;
    } else {
      return (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          {props.buttonTitle}
        </Button>
      );
    }
  };

  return (
    <>
      {renderButton()}

      <Drawer
        title={props.buttonTitle}
        width={512}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark form={form} initialValues={props.editValues}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="SequencingProvider"
                label="Sequencing Provider"
                rules={[{ required: true, message: 'Please enter a name for the provider' }]}
              >
                <Select onChange={(value) => setProvider(value)}>
                  {providersList.map((prov: any) => {
                    return (
                      <Select.Option key={prov.ID} value={prov.ID}>
                        {prov.Name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="SequencingKitID"
                label="Sequencing Kit ID"
                rules={[{ required: true, message: 'Please Input the Sequencing Kit ID' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default SequencingDrawer;
