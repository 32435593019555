import { adminAddAdmin, adminUpdateAdmin } from '../../API/Admins';
import { listOrganizations } from '../../API/Organizations';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import React, { useState } from 'react';

type Props = {
  refresh: () => void;
  buttonTitle: string;
  buttonType?: string;
  editValues?: any;
};

const AdminDrawer: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [orgs, setOrgs] = useState<any>([]);
  const [organization, setOrganization] = useState<string>('');
  const [form] = Form.useForm();

  if (!loaded) {
    listOrganizations().then((organizations) => {
      setOrgs(organizations);
      setLoaded(true);
    });
  }

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = () => {
    if (props.buttonType === 'edit') {
      adminUpdateAdmin(
        props.editValues.ID,
        form.getFieldValue('given_name'),
        form.getFieldValue('family_name'),
        form.getFieldValue('email'),
        organization,
      ).then(() => {
        props.refresh();
        setOpen(false);
      });
    } else {
      adminAddAdmin(
        form.getFieldValue('given_name'),
        form.getFieldValue('family_name'),
        form.getFieldValue('email'),
        form.getFieldValue('password'),
        organization,
      ).then(() => {
        props.refresh();
        setOpen(false);
      });
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const renderButton = () => {
    if (props.buttonType === 'edit') {
      return <a onClick={showDrawer}>{props.buttonTitle}</a>;
    } else {
      return (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          {props.buttonTitle}
        </Button>
      );
    }
  };

  return (
    <>
      <Space>{renderButton()}</Space>
      <Drawer
        title={props.buttonTitle}
        // width={1024}
        size={'large'}
        onClose={onClose}
        visible={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" requiredMark={true} form={form} initialValues={props.editValues}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="given_name"
                label="Name"
                rules={[{ required: true, message: 'Please enter a name' }]}
              >
                <Input placeholder="John" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="family_name"
                label="Family Name"
                rules={[{ required: true, message: 'Please enter a family name' }]}
              >
                <Input placeholder="Smith" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="email"
                label="e-mail"
                rules={[{ required: true, message: 'Please enter an email' }]}
              >
                <Input placeholder="josh.smith@example.com" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="custom:organization"
                label="Organization"
                rules={[{ required: true, message: 'Please select an organization' }]}
              >
                <Select onChange={(value) => setOrganization(value)}>
                  {orgs.map((org: any) => {
                    return (
                      <Select.Option key={org.OrganizationID} value={org.OrganizationID}>
                        {org.OrganizationName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {props.buttonType !== 'edit' && (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: 'Please enter a password' }]}
                >
                  <Input type={'password'} placeholder="*******" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default AdminDrawer;
