import React, { useRef, useState } from "react";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { getPatientsList } from "../API/API";
import { FiSettings } from "react-icons/fi";
import Layout from "../components/Layout";
import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import DnaSampleCollectedModal from "src/components/DnaSampleCollectedModal";
import CreatePatientForm from "src/components/CreatePatientForm";
import AddCareTeamMemberToPatientDrawer from "src/components/AddCareTeamMemberToPatientDrawer";

const PatientsManagement: React.FC = () => {
  const dnaSampleDisclosure = useDisclosure();
  const assignCtmDisclosure = useDisclosure();
  const [selectedPatient, setSelectedPatient] = useState<any>();
  const [patientsList, setPatientsList] = useState<any>([]);
  const [filteredPatientsList, setFilteredPatientsList] = useState<any>([]);

  const actionRef = useRef<ActionType>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const refetchPatients = async () => {
    const data = await getPatientsList();
    setPatientsList(data);
  };

  const columns: ProColumns<any>[] = [
    {
      title: "First Name",
      dataIndex: "name",
    },
    {
      title: "Last Name",
      dataIndex: "familyName",
    },
    {
      title: "Kit Serial Number",
      dataIndex: "kitSerialNumber",
      render: (item: any) => {
        return <span>{item !== "-" ? item : "N/A"}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Last Update",
      key: "updatedAt",
      dataIndex: "updatedAt",
      valueType: "date",
    },
    {
      title: "Actions",
      render: (_, data) => {
        return (
          <Menu>
            <MenuButton as={IconButton} icon={<FiSettings />} />
            <MenuList>
              <MenuItem
                onClick={() => {
                  dnaSampleDisclosure.onOpen();
                  setSelectedPatient(data);
                }}
              >
                DNA Sample Collected
              </MenuItem>
              <MenuItem
                onClick={() => {
                  assignCtmDisclosure.onOpen();
                  setSelectedPatient(data);
                }}
              >
                Assign Care Team Member
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  return (
    <Layout title="Patients management">
      <PageContainer>
        <ProTable<any>
          headerTitle="RareCare Patients"
          actionRef={actionRef}
          rowKey="email"
          locale={{}}
          columns={columns}
          request={async (params) => {
            let data = patientsList;

            if (!data.length) {
              data = await getPatientsList();
              setPatientsList(data);
            }

            if (Object.keys(params).length > 2) {
              const filterKeys = ["name", "familyName", "email"];
              const filters = [];

              for (const key of filterKeys) {
                if (params[key]) filters.push([key, params[key]]);
              }

              const filteredData = data.filter((item: any) => {
                return filters.every(([key, value]) => {
                  return item[key]?.toLowerCase().includes(value.toLowerCase());
                });
              });

              setFilteredPatientsList(filteredData);
            } else {
              setFilteredPatientsList(data);
            }

            return null;
          }}
          dataSource={filteredPatientsList}
          search={{
            labelWidth: 120,
          }}
          toolBarRender={() => [
            <Button onClick={onOpen} colorScheme="blue">
              Add Patient
            </Button>,
          ]}
        />
      </PageContainer>
      <DnaSampleCollectedModal
        isOpen={dnaSampleDisclosure.isOpen}
        onClose={dnaSampleDisclosure.onClose}
        patient={selectedPatient}
        onSubmissionCompleted={async () => {
          const data = await getPatientsList();
          setPatientsList(data);
        }}
      />

      <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
        <DrawerContent>
          <DrawerHeader>
            <Heading as="h3" fontSize="20px" color="brand.gray1">
              Add Patient
            </Heading>
          </DrawerHeader>
          <DrawerCloseButton />
          <CreatePatientForm
            onSuccess={(response: any) => {
              refetchPatients();
            }}
          />
        </DrawerContent>
      </Drawer>

      {assignCtmDisclosure.isOpen ? (
        <AddCareTeamMemberToPatientDrawer
          patientData={selectedPatient}
          isOpen={assignCtmDisclosure.isOpen}
          onClose={assignCtmDisclosure.onClose}
        />
      ) : null}
    </Layout>
  );
};

export default PatientsManagement;
