import {
  Box,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Stack,
  Button,
  Spinner,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { registerCareTeamMember } from "src/API/careTeamMember";
import Select from "src/components/Select";

function validatePassword(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d])[\w\W]{12,}$/;
  return passwordRegex.test(password);
}
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  title: "",
  specialty: "",
  medicalAffiliation: "",
  phoneNumber: "",
  password: "",
  passwordConfirmation: "",
};

export default function CreateCareTeamForm({ onSuccess }: any) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);

  const handleChange = (name: string, value: string | boolean) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignup = async (e: any) => {
    e.preventDefault();
    const data = {
      name: state.firstName,
      familyName: state.lastName,
      email: state.email,
      title: state.title,
      specialty: state.specialty,
      phoneNumber: state.phoneNumber,
      medicalAffiliation: state.medicalAffiliation,
      password: state.password,
    };

    if (state.password !== state.passwordConfirmation) return;

    try {
      setLoading(true);
      const response = await registerCareTeamMember(data);
      if (response.error) {
        alert(response.error || "Error creating care team member. Please try again.");
        return;
      }
      onSuccess();
      setState(initialState);
      alert("Care team member created successfully!");
    } catch (error: any) {
      console.error(error);
      const errorMessage = error?.response?.data?.error || error.message;
      alert(errorMessage || "Error creating care team member. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box as="form" height="100%" overflow="scroll" p={8} onSubmit={handleSignup}>
      <Stack spacing={4}>
        <HStack>
          <FormControl id="firstName" isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              autoComplete="off"
              value={state.firstName}
              required
              onChange={(e) => handleChange("firstName", e.target.value)}
            />
          </FormControl>
          <FormControl id="lastName">
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              autoComplete="off"
              value={state.lastName}
              required
              onChange={(e) => handleChange("lastName", e.target.value)}
            />
          </FormControl>
        </HStack>
        <FormControl id="email" isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            autoComplete="off"
            value={state.email}
            required
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </FormControl>

        <FormControl id="phoneNumber">
          <FormLabel>Phone number</FormLabel>
          <Input
            type="text"
            autoComplete="off"
            value={state.phoneNumber}
            required
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
          />
        </FormControl>

        <FormControl id="previous-genetic-screening" isRequired>
          <FormLabel>Role / title</FormLabel>
          <Select
            options={[
              { label: "Family member", value: "ctm_family" },
              { label: "Medical Professional", value: "ctm_medical" },
              { label: "Advocate", value: "ctm_sample_collection" },
            ]}
            required
            onChange={({ value }: any) => handleChange("title", value)}
          />
        </FormControl>

        {state.title === "Medical Professional" && (
          <>
            <FormControl id="specialty">
              <FormLabel>Specialty</FormLabel>
              <Input
                type="text"
                autoComplete="off"
                value={state.specialty}
                required
                onChange={(e) => handleChange("specialty", e.target.value)}
              />
              <FormHelperText>
                Your specialty or focus (Geneticist, Neurology, etc..)
              </FormHelperText>
            </FormControl>

            <FormControl id="medicalAffiliation">
              <FormLabel>Medical affilitation</FormLabel>
              <Input
                type="text"
                autoComplete="off"
                value={state.medicalAffiliation}
                required
                onChange={(e) => handleChange("medicalAffiliation", e.target.value)}
              />
              <FormHelperText>Your medical affilitation (ie: Mt Sinai, NYU, etc..)</FormHelperText>
            </FormControl>
          </>
        )}

        <FormControl id="password" isRequired isInvalid={!validatePassword(state.password)}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            autoComplete="off"
            value={state.password}
            required
            onChange={(e) => handleChange("password", e.target.value)}
          />
          <FormErrorMessage>
            Password must be at least 12 characters, has uppercase, lowercase, number and a special
            character (^$*@#%&=+-)
          </FormErrorMessage>
        </FormControl>

        <FormControl
          id="passwordConfirmation"
          isRequired
          isInvalid={state.password !== state.passwordConfirmation}
        >
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            autoComplete="off"
            value={state.passwordConfirmation}
            required
            onChange={(e) => handleChange("passwordConfirmation", e.target.value)}
          />
          <FormErrorMessage>Passwords do not match</FormErrorMessage>
        </FormControl>

        <Stack spacing={10} pt={2}>
          <Button
            loadingText="Submitting"
            size="lg"
            type="submit"
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
            isDisabled={
              state.password !== state.passwordConfirmation ||
              !validatePassword(state.password) ||
              loading
            }
          >
            {loading ? "Loading..." : "Add Care Team Member"}
            {loading && <Spinner />}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
