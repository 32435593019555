import Layout from "src/components/Layout";
import { useGlobalState } from "../contexts/globalState.context";
import { listOrganizationCases } from "../API/Organizations";
import CaseDrawer from "../components/CaseDrawer/CaseDrawer";
import { PageContainer, ProColumns, ProTable } from "@ant-design/pro-components";
import { Tag } from "antd";
// import React, { useEffect, useState } from 'react';
import React, { useState } from "react";
import { useAuth } from "src/contexts/auth.context";

const Cases: React.FC = () => {
  const { user } = useAuth();
  const { isGlobalAdmin, getOrganizationName } = useGlobalState();
  const [cases, setCases] = useState([]);

  const columns: ProColumns<any>[] = [
    {
      title: "Tag",
      dataIndex: "Tag",
      sorter: (a, b) => a.Tag.localeCompare(b.Tag),
      render: (item, rareCase) => {
        return <CaseDrawer caseID={rareCase["CaseID"]} title={rareCase["Tag"]} />;
      },
    },
    {
      title: "Organization",
      dataIndex: "Organization",
      render: (item, rareCase) => {
        const rarecarecase = rareCase as any;
        const name = getOrganizationName(rarecarecase.Organization);
        return <Tag color="blue">{name}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "CaseStatus",
      render: (item) => {
        if (item === "IN_PROGRESS") {
          return <Tag color="green">In Progress</Tag>;
        } else if (item === "OPEN") {
          return <Tag color="grey">Open</Tag>;
        } else if (item === "COMPLETED") {
          return <Tag color="blue">Completed</Tag>;
        } else {
          return <Tag color="grey">{item}</Tag>;
        }
      },
    },
    {
      title: "Case Managers",
      dataIndex: "CaseManagers",
      render: (item) => {
        return <Tag color="green">{item}</Tag>;
      },
    },
    {
      title: "Created At",
      dataIndex: "CreatedAt",
    },

    // other columns go here
  ];

  return (
    <Layout title="Cases">
      <PageContainer>
        <ProTable
          columns={columns}
          // dataSource={Array.isArray(cases) ? cases : []}
          request={async (params) => {
            let data = cases;

            if (!data.length) {
              if (isGlobalAdmin()) {
                data = await listOrganizationCases("GLOBAL");
                setCases(data);
              } else {
                if (!user.organization) {
                  data = await listOrganizationCases(user.organization);
                  setCases(data);
                }
              }
            }

            if (params["Tag"]) {
              data = data.filter((item) => {
                const tag = item["Tag"]?.toLowerCase();
                return tag?.includes(params["Tag"].toLowerCase());
              });
            }

            if (params["Organization"]) {
              data = data.filter((item) => {
                const name = getOrganizationName(item["Organization"]);
                if (!name) return false;
                return name.includes(params["Organization"]);
              });
            }

            return {
              data: data,
              success: true,
              total: data.length,
            };
          }}
          search={{
            filterType: "query",
            searchText: "Search",
          }}
          rowKey="key"
          headerTitle="RareCare Cases"
        />
      </PageContainer>
    </Layout>
  );
};

export default Cases;
