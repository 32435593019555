import Layout from 'src/components/Layout';
import { listSequencing, listSequencingProviders } from '../API/Sequencing';
import SequencingDrawer from '../components/SequencingDrawer/SequencingDrawer';
import SequencingProviderDrawer from '../components/SequencingProviderDrawer/SequencingProviderDrawer';
import type { ProColumns } from '@ant-design/pro-components';
import { PageContainer, ProTable } from '@ant-design/pro-components';
import React, { useState } from 'react';

const Sequencing: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [providersList, setProvidersList] = useState([]);
  const [sequencingsList, setSequencingsList] = useState([]);

  // const actionRef = useRef<ActionType>();

  if (!loaded) {
    listSequencingProviders().then((orgs) => {
      console.log('ORGANIZATIONS', orgs);
      setProvidersList(orgs);
      listSequencing().then((res) => {
        setSequencingsList(res);
        setLoaded(true);
      });
    });
  }

  const refresh = () => {
    setLoaded(false);
  };

  const columns: ProColumns<any>[] = [
    {
      title: 'Name',
      dataIndex: 'Name',
    },
    {
      title: 'Options',
      render: (item) => {
        return (
          <SequencingProviderDrawer
            editValues={item as any}
            refresh={refresh}
            buttonTitle="Edit"
            buttonType="edit"
          />
        );
      },
    },
  ];

  const sequencingsColumns: ProColumns<RareCare.Sequencing>[] = [
    {
      title: 'Case ID',
      dataIndex: 'CaseID',
    },
    {
      title: 'Provider',
      render: (item: any) => {
        let ProvName = 'N/A';
        providersList.forEach((provider) => {
          if (provider.ID === item.ProviderID) {
            ProvName = provider.Name;
          }
        });
        return <>{ProvName}</>;
      },
    },
    {
      title: 'Sequencing Kit ID',
      dataIndex: 'ExternalID',
    },
    {
      title: 'Options',
      render: (item: any) => {
        return (
          <SequencingDrawer
            editValues={item}
            caseID={item.CaseID}
            refresh={refresh}
            buttonTitle="Edit"
            buttonType="edit"
          />
        );
      },
    },
  ];

  return (
    <Layout title="Sequencing">
      <PageContainer>
        <ProTable
          headerTitle="Sequencing Providers"
          rowKey="key"
          toolBarRender={() => [
            <SequencingProviderDrawer
              buttonTitle={'New Sequencing Provider'}
              refresh={() => {
                setLoaded(false);
              }}
            />,
          ]}
          dataSource={providersList}
          // request={rule}
          columns={columns}
          // rowSelection={{
          //   onChange: (_, selectedRows) => {
          //     setSelectedRows(selectedRows);
          //   },
          // }}
        />

        <ProTable
          headerTitle="Sequencings"
          rowKey="key"
          // toolBarRender={() => [
          //   <SequencingProviderDrawer
          //     buttonTitle={'New Sequencing Provider'}
          //     refresh={() => {
          //       setLoaded(false);
          //     }}
          //   />,
          // ]}
          dataSource={sequencingsList}
          // request={rule}
          columns={sequencingsColumns}
          // rowSelection={{
          //   onChange: (_, selectedRows) => {
          //     setSelectedRows(selectedRows);
          //   },
          // }}
        />
      </PageContainer>
    </Layout>
  );
};

export default Sequencing;
