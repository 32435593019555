import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  List,
  ListItem,
  Avatar,
  useToast,
  Box,
  Divider,
  HStack,
  Text,
  VStack,
  Alert,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import {
  inviteCareTeamMember,
  searchCareTeamMembers,
  sendCareTeamMemberInviteEmail,
} from "src/API/careTeamMember";
import CareTeamInviteModal from "src/components/CareTeamInviteModal";
import ConfirmationModal from "src/components/ConfirmationModal";
import Loader from "src/components/Loader";

const AddCareTeamMemberToPatientDrawer = ({ patientData, isOpen, onClose }) => {
  const confirmDisclosure = useDisclosure();
  const careTeamInviteModalDisclosure = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [result, setResult] = useState(null);

  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setResult(null);
    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());
    searchCareTeamMembers(formDataObject.searchTerm)
      .then((data) => {
        setResult(data);
      })
      .catch((error) => {
        alert("Error searching for care team members");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <CareTeamInviteModal
        isOpen={careTeamInviteModalDisclosure.isOpen}
        onClose={careTeamInviteModalDisclosure.onClose}
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(event.target);
          const formDataObject = Object.fromEntries(formData.entries());
          const email = formDataObject.email;

          if (patientData.careTeamMembers?.find((item) => item.id === selectedTeamMember.id)) {
            return toast({
              title: "This Care Team Member is already part of " + patientData.name + "'s team.",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          }

          sendCareTeamMemberInviteEmail({ email, patientId: patientData.id })
            .then(() => {
              toast({
                title: "Invite sent successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
              careTeamInviteModalDisclosure.onClose();
            })
            .catch((error) => {
              const errorData = error?.response?.data;
              toast({
                title: errorData.error || "Error sending invite. Please try again later.",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
            });
        }}
      />
      <ConfirmationModal
        title="Add Care Team member"
        text={
          <p>
            Are you sure you want to invite{" "}
            <strong>{`${selectedTeamMember?.name} ${selectedTeamMember?.familyName}`}</strong> to
            join {patientData.name}'s Care Team ?
          </p>
        }
        isOpen={confirmDisclosure.isOpen}
        onClose={confirmDisclosure.onClose}
        onConfirm={() => {
          inviteCareTeamMember({
            patientId: patientData.id,
            careTeamMemberId: selectedTeamMember.id,
          })
            .then(() => {
              toast({
                title: "Care team invitation sent successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
            })
            .catch((error) => {
              console.error(error);
              const errorData = error?.response?.data;
              toast({
                title: errorData.error || "Error sending invitation. Please try again later.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
            });
        }}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
          setResult(null);
        }}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent onSubmit={handleSubmit}>
          <DrawerCloseButton />
          <DrawerHeader>Add care team member to {patientData.name}</DrawerHeader>

          <DrawerBody as="form" id="add-care-team-form">
            <FormControl>
              <FormLabel>Search care team members by name or email</FormLabel>
              <Input
                required
                minLength={2}
                maxLength={255}
                name="searchTerm"
                type="text"
                placeholder="type here to search..."
                disabled={isLoading}
              />
              <Button
                type="submit"
                colorScheme="facebook"
                form="add-care-team-form"
                mt="2"
                width="100%"
                disabled={isLoading}
              >
                Search
              </Button>
            </FormControl>

            {isLoading ? <Loader /> : null}

            {result?.length ? (
              <Box mt="4" border="1px solid lightgray" p="4" borderRadius="md">
                <span>
                  {result?.length} result{result?.length === 1 ? "" : "s"} found
                </span>
                <Divider my="2" />
                <List spacing={3}>
                  {result?.map((item, index) => (
                    <ListItem
                      key={item.id}
                      bg={index % 2 === 0 ? "gray.100" : "white"}
                      p="2"
                      borderRadius="md"
                      _hover={{ bg: "gray.200" }}
                      cursor="pointer"
                      display="flex"
                      alignItems="center"
                      gap="2"
                      onClick={() => {
                        setSelectedTeamMember(item);
                        confirmDisclosure.onOpen();
                      }}
                    >
                      <Avatar
                        size="md"
                        name={`${item.name} ${item.familyName}`}
                        src={item.avatarUrl}
                      />
                      <VStack align="left" width="full">
                        <Text fontWeight="bold">{`${item.name} ${item.familyName}`}</Text>
                        <Text color="gray.500">{item.email}</Text>
                      </VStack>
                      <HStack spacing="2" ml="auto" bg="green.200" p="2" borderRadius="full">
                        <FiPlus color="black" />
                      </HStack>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : null}

            {result !== null && result.length === 0 ? (
              <Alert mt="6" status="info" borderRadius="md">
                <VStack>
                  <Text fontWeight="bold"> No results found!</Text>
                  <Text>
                    Looks like the person you are looking for is not yet registered on our platform.
                    Would you like to invite this person ?
                  </Text>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      onClose();
                      careTeamInviteModalDisclosure.onOpen();
                    }}
                  >
                    Send an invite
                  </Button>
                </VStack>
              </Alert>
            ) : null}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" type="button" disabled={isLoading} mr={3} onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddCareTeamMemberToPatientDrawer;
