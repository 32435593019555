// @ts-ignore
/* eslint-disable */
import { notification } from "antd";
import { Amplify, API, Auth } from "aws-amplify";
import axios from "axios";

const ADMIN_API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;
const AWS_REGION = process.env.REACT_APP_AWS_REGION || "us-east-1";
const ADMIN_POOL_ID = process.env.REACT_APP_ADMIN_POOL_ID || "NOT_SET";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "NOT_SET";

export function awsConfigure() {
  Amplify.configure({
    Auth: {
      region: AWS_REGION,
      userPoolId: ADMIN_POOL_ID,
      userPoolWebClientId: CLIENT_ID,
    },
  });
  API.configure({
    Auth: {
      region: AWS_REGION,
      userPoolId: ADMIN_POOL_ID,
      userPoolWebClientId: CLIENT_ID,
    },
  });
}

export async function APIPOSTRequest(command: string, params: any, message?: string) {
  return axios.post(ADMIN_API_ENDPOINT + command, params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: "Error" + res.data.Payload,
      });
    } else {
      if (message) {
        notification.success({
          message: message,
        });
      }
    }
    return res.data.Payload;
  });
}

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }): Promise<API.CognitoUser> {
  awsConfigure();
  return Auth.currentAuthenticatedUser({
    bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then((user) => {
      return <API.CognitoUser>user;
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export async function currentUserInfo() {
  awsConfigure();
  return Auth.currentUserInfo().then((user) => {
    return user;
  });
}

export async function adminGetUsersList() {
  return axios.get(ADMIN_API_ENDPOINT + "/admin/user/list").then((res) => {
    let users: any = [];
    res.data.Payload.map((user: any) => {
      user.Attributes.map((attr: any) => {
        user[attr["Name"]] = attr["Value"];
      });
      users.push(user);
    });
    return users;
  });
}

export async function getPatientsList() {
  return axios.get(ADMIN_API_ENDPOINT + "/patients").then((res) => {
    return res.data;
  });
}

export async function updatePatient({ patientId, data }: any) {
  return axios.put(ADMIN_API_ENDPOINT + `/patients/${patientId}`, data);
}

export async function addMedicalProfessional(data: {
  name: string;
  familyName: string;
  email: string;
  licenseNumber: string;
  password: string;
  careTeamType: string;
  organization: string;
}) {
  const params = {
    Name: data.name,
    FamilyName: data.familyName,
    Email: data.email,
    LicenseNumber: data.licenseNumber,
    Password: data.password,
    CareTeamType: data.careTeamType,
    Organization: data.organization,
  };

  return axios.post(ADMIN_API_ENDPOINT + "/medical_professional/new", params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: "Error" + res.data.Payload,
      });
    } else {
      notification.success({
        message: "Medical Professional Added Successfully",
      });
    }
  });
}

export async function addPatient(
  name: string,
  familyName: string,
  email: string,
  password: string,
  organization: string
) {
  const params = {
    Name: name,
    FamilyName: familyName,
    Email: email,
    Password: password,
    Organization: organization,
  };

  return APIPOSTRequest("/patient/new", params, "Patient Added Successfully");
}

export async function changePassword(options: API.ChangePasswordOptions) {
  awsConfigure();
  return Auth.changePassword(options.user, options.oldPassword, options.newPassword)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function signOutUser(options?: { [key: string]: any }) {
  awsConfigure();
  return Auth.signOut()
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function updateUser(
  user: string | any,
  attributes: API.CurrentUser,
  options?: { [key: string]: any }
): Promise<string> {
  awsConfigure();
  return Auth.updateUserAttributes(user, attributes)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  awsConfigure();
  return Auth.signIn(body.username!, body.password)
    .then((response) => {
      console.log("Positive Response!!");
      response["status"] = "ok";
      response["type"] = "account"; //TODO
      response["currentAuthority"] = "user"; //TODO
      return response;
    })
    .catch((err) => {
      console.log("error signing in", err);
      notification.error({
        message: "Error Signing In",
        description: err.message,
      });
      return {
        status: "error",
        error: err.toString(),
      };
    });
}

export async function signup(
  body: API.SignupParams,
  options?: { [key: string]: any }
): Promise<any> {
  awsConfigure();

  const params = {
    username: body["email"],
    password: body["password"],
    attributes: {
      given_name: body["given_name"],
      family_name: body["family_name"],
      email: body["email"],
    },
  };

  return Auth.signUp(params).then((response) => {
    return response;
  });
}
