import { notification } from "antd";
import axios from "axios";

const ADMIN_API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;

export async function registerPatient(data: any) {
  const endpoint = ADMIN_API_ENDPOINT + "/patients/register";
  return axios.post(endpoint, data).then(({ data }: any) => {
    return data;
  });
}

export async function getPatient(userID: string) {
  const params = {
    UserID: userID,
  };
  return axios.post(ADMIN_API_ENDPOINT + "/patient/get", params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: "Error Getting Patient",
        description: res.data.Payload,
      });
    }
    return res.data.Payload;
  });
}

export async function updatePatient(UserID: string, Name: string, FamilyName: string) {
  const patient = {
    UserID: UserID,
    Name: Name,
    FamilyName: FamilyName,
  };

  console.log("==================");
  console.log(patient);

  return axios.post(ADMIN_API_ENDPOINT + "/patient/update", patient).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: "Error Updating Patient",
        description: res.data.Payload,
      });
    } else {
      notification.success({
        message: "Patient Updated Successfully",
      });
    }
  });
}
