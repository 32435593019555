import { getCase, updateCaseDetails, uploadCaseFile, downloadFile } from '../../API/Case';
import { caseSequencing } from '../../API/Sequencing';
import SequencingDrawer from '../SequencingDrawer/SequencingDrawer';
import {
  Upload,
  Table,
  Button,
  Col,
  Card,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam } from 'antd/es/upload';

type Props = {
  // refresh: () => void;
  caseID: any;
  title: string;
};

const CaseDrawer: React.FC<Props> = (props) => {
  const [sequencings, setSequencings] = useState([]);
  const [rareCareCase, setRareCareCase] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const showDrawer = () => {
    getCase(props.caseID).then((res) => {
      caseSequencing(res.CaseID).then((seqs) => {
        console.log(seqs);
        setRareCareCase(res);
        setSequencings(seqs);
        setOpen(true);
      });
    });
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleFileupload: (info: UploadChangeParam<UploadFile>) => void = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (!uploading) {
      setUploading(true);
      getBase64(info.file.originFileObj as RcFile, (dataAsURL) => {
        notification.info({
          message: 'Uploading ' + info.file.name + '...',
        });
        uploadCaseFile(props.caseID, info.file.name, dataAsURL).then(() => {
          setUploading(false);
          getCase(props.caseID).then((res) => {
            caseSequencing(res.CaseID).then((seqs) => {
              setRareCareCase(res);
              setSequencings(seqs);
              setOpen(true);
            });
          });
        });
        // uploadSequencingResult(SequencingID, info.file.name, dataAsURL).then(() => {
        //   setUploading(false);
        //   getCase(props.caseID).then((res) => {
        //     caseSequencing(res.CaseID).then((seqs) => {
        //       setRareCareCase(res);
        //       setSequencings(seqs);
        //       setOpen(true);
        //     });
        //   });
        // });
      });
    }
  };

  const onSubmit = () => {
    updateCaseDetails(
      props.caseID,
      form.getFieldValue('Age'),
      form.getFieldValue('Gender'),
      form.getFieldValue('Tag'),
      form.getFieldValue('Ethnicity'),
      form.getFieldValue('MainDiagnosis'),
    ).then(() => {
      // props.refresh();
      setOpen(false);
    });
  };

  const handleDownloadOnClick = (filename: string) => {
    downloadFile(props.caseID, filename).then((data) => {
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      const blobFile = new Blob([data]);
      console.log(blobFile);
      const url = window.URL.createObjectURL(blobFile);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const renderButton = () => {
    if (props.title === '') {
      return <a onClick={showDrawer}>{props.caseID}</a>;
    }
    return <a onClick={showDrawer}>{props.title}</a>;
  };

  return (
    <>
      {renderButton()}

      <Drawer
        // title={props.buttonTitle}
        // width={1024}
        size={'large'}
        onClose={onClose}
        visible={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" requiredMark form={form} initialValues={rareCareCase}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="Tag"
                label="Tag"
                rules={[{ required: true, message: 'Please enter a name for the organization' }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Age" label="Age">
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Gender" label="Gender">
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="Ethnicity" label="Ethnicity">
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="MainDiagnosis" label="Main Diagnosis">
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {rareCareCase && (
          <Card title="Case Files">
            <Upload
              onChange={(info) => {
                handleFileupload(info);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
            <Table dataSource={rareCareCase.FileList}>
              <Table.Column
                title="Filename"
                dataIndex="Filename"
                key="Filename"
                render={(item) => {
                  const filepath = item.split('/');
                  const filename = filepath.at(-1);
                  return (
                    <a
                      onClick={() => {
                        handleDownloadOnClick(filename);
                      }}
                    >
                      {filename}
                    </a>
                  );
                }}
              />
              <Table.Column title="Size" dataIndex="Size" key="Size" />
              <Table.Column
                title="Date"
                dataIndex="LastModified"
                key="LastModified"
                render={(item) => {
                  const date = new Date(item);
                  return <>{date.toLocaleDateString()}</>;
                }}
              />
            </Table>
          </Card>
        )}

        <SequencingDrawer buttonTitle={'Add Sequencing'} caseID={props.caseID} refresh={() => {}} />

        <Col>
          {sequencings &&
            sequencings.map((seq: any) => {
              return <Card title={seq.ExternalID}></Card>;
            })}
        </Col>
      </Drawer>
    </>
  );
};

export default CaseDrawer;
