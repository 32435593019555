import React, { PropsWithChildren, useState } from "react";
import {
  BarcodeOutlined,
  ExperimentOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Heading,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth.context";
import TMAMobileLogo from "../Icons/TMAMobileLogo";
import Sider from "antd/es/layout/Sider";

const routes = [
  {
    key: "1",
    title: "CTMs management",
    label: <Link to="/care-team-members?key=1">CTMs management</Link>,
    icon: <UserAddOutlined />,
  },
  {
    key: "2",
    title: "Patients management",
    label: <Link to="/patients?key=2">Patients management</Link>,
    icon: <UserAddOutlined />,
  },
  {
    key: "3",
    title: "Cases",
    label: <Link to="/cases?key=3">Cases</Link>,
    icon: <ExperimentOutlined />,
  },
  {
    key: "4",
    title: "Organizations management",
    label: <Link to="/organizations?key=4">Organizations management</Link>,
    icon: <HomeOutlined />,
  },
  {
    key: "5",
    title: "Sequencing management",
    label: <Link to="/sequencing?key=5">Sequencing management</Link>,
    icon: <BarcodeOutlined />,
  },
];

const Layout: React.FC<PropsWithChildren<{ title: string }>> = ({ children, title }) => {
  const { user, signOut } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");

  return (
    <VStack align="left" background="gray.200" gap="0">
      <HStack
        justifyContent="space-between"
        padding="0 40px"
        width="100vw"
        height="60px"
        background="blue.900"
      >
        <HStack>
          <TMAMobileLogo />
          <Text color="white">TMA | Admin</Text>
        </HStack>

        <HStack gap="20px">
          <Text color="white" size="sm">
            {user.name}
          </Text>
          <Avatar size="sm" name={user.name + " " + user.familyName} src={user.avatarUrl} />

          <Tooltip label="logout">
            <IconButton
              colorScheme="red"
              aria-label="logout"
              size="md"
              icon={<LogoutOutlined />}
              onClick={signOut}
            />
          </Tooltip>
        </HStack>
      </HStack>
      <Flex>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            style={{ height: "calc(100vh - 100px)" }}
            defaultSelectedKeys={[key || "1"]}
            mode={"inline"}
            theme={"light"}
            items={routes}
          />
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              background: "white",
              borderRadius: "0px",
              borderTop: "1px solid lightgray",
              fontSize: "16px",
              width: "100%",
            }}
          />
        </Sider>
        <Box maxHeight="calc(100vh - 70px)" flex="1" overflow="scroll">
          <Box background="white" padding="10px 38px">
            <Heading as="h1" size="md" noOfLines={1}>
              {title || "Dashboard"}
            </Heading>
          </Box>
          {children}
        </Box>
      </Flex>
    </VStack>
  );
};

export default Layout;
