import { listOrganizations } from '../../API/Organizations';
import OrganizationDrawer from './OrganizationDrawer/OrganizationDrawer';
import { ProColumns, ProTable } from '@ant-design/pro-components';
import { Tag } from 'antd';
import React, { useState } from 'react';

const OrganizationsTable: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [orgsList, setOrgsList] = useState([]);

  if (!loaded) {
    listOrganizations().then((orgs: any) => {
      setOrgsList(orgs);
      setLoaded(true);
    });
  }

  const refresh = () => {
    setLoaded(false);
  };

  const columns: ProColumns<any>[] = [
    {
      title: 'Name',
      dataIndex: 'OrganizationName',
    },
    {
      title: 'Mnemonic',
      dataIndex: 'Mnemonic',
    },
    {
      title: 'Members',
      dataIndex: 'Members',
      render: (item) => {
        // return item.map((member) => {return <Tag color="green">{item}</Tag>});
        return <Tag color="green">{item}</Tag>;
      },
    },
    {
      title: 'Options',
      render: (item) => {
        return (
          <OrganizationDrawer
            editValues={item as any}
            refresh={refresh}
            buttonTitle="Edit"
            buttonType="edit"
          />
        );
      },
    },
  ];

  return (
    <ProTable<API.RuleListItem, API.PageParams>
      headerTitle="RareCare Organizations"
      // actionRef={actionRef}
      rowKey="key"
      // search={{
      //   labelWidth: 120,
      // }}
      search={false}
      toolBarRender={() => [
        <OrganizationDrawer
          buttonTitle={'New Organization'}
          refresh={() => {
            setLoaded(false);
          }}
        />,
      ]}
      dataSource={orgsList}
      // request={rule}
      columns={columns}
      // rowSelection={{
      //   onChange: (_, selectedRows) => {
      //     setSelectedRows(selectedRows);
      //   },
      // }}
    />
  );
};

export default OrganizationsTable;
