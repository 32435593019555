import { notification } from 'antd';
import axios from 'axios';

const ADMIN_API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;

/**
 * Lists all the organizations in RareCare
 *
 * @returns {Promise} Promise object represents the list of organizations
 * @throws {Error} If the request fails
 *
 */
export async function listSequencingProviders(): Promise<RareCare.SequencingProvider[]> {
  return axios.get(ADMIN_API_ENDPOINT + '/admin/sequencing/providers/list').then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Retrieving Providers List',
        description: res.data.Payload,
      });
      return [];
    }
    return res.data.Payload;
  });
}

/**
 * Creates a new organization in RareCare
 * @param orgName
 */
export async function addSequencingProvider(name: string) {
  return axios
    .post(ADMIN_API_ENDPOINT + '/admin/sequencing/providers/add', { Name: name })
    .then((res) => {
      if (res.data.Error) {
        notification.error({
          message: 'Error Creating New Sequencing Provider',
          description: res.data.Payload,
        });
      } else {
        notification.success({
          message: 'Sequencing Provider Added Successfully',
        });
      }
    });
}

/**
 * Creates a new sequencing in RareCare
 * @param SequencingProviderID
 * @param CaseID
 * @param SequencingKitID
 */
export async function newSequencing(
  CaseID: string,
  SequenceProviderID: string,
  SequencingKitID: string,
) {
  const params = {
    ExternalID: SequencingKitID,
    ProviderID: SequenceProviderID,
    CaseID: CaseID,
  };

  return axios.post(ADMIN_API_ENDPOINT + '/admin/sequencing/add', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Creating New Sequencing',
        description: res.data.Payload,
      });
    } else {
      notification.success({
        message: 'Sequencing Added Successfully',
      });
    }
  });
}

export async function listSequencing(): Promise<RareCare.Sequencing[]> {
  return axios.get(ADMIN_API_ENDPOINT + '/admin/sequencing/list').then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Retrieving Providers List',
        description: res.data.Payload,
      });
      return [];
    }
    return res.data.Payload;
  });
}

export async function uploadSequencingResult(sequencingID: string, filename: string, data: string) {
  const params = {
    SequencingID: sequencingID,
    Filename: filename,
    B64Data: data,
  };
  return axios.post(ADMIN_API_ENDPOINT + '/admin/sequencing/files/upload', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Uploading Sequencing Result',
        description: res.data.Payload,
      });
    } else {
      notification.success({
        message: 'Sequencing Result Uploaded Successfully',
      });
    }
  });
}

export async function caseSequencing(CaseID: string) {
  const params = {
    CaseID: CaseID,
  };

  return axios.post(ADMIN_API_ENDPOINT + '/admin/sequencing/case/list', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error getting the list of sequencings for the case',
        description: res.data.Payload,
      });
    }
    return res.data.Payload;
  });
}

export async function sequencingFiles(SequencingID: string) {
  const params = {
    SequencingID: SequencingID,
  };

  return axios.post(ADMIN_API_ENDPOINT + '/admin/sequencing/files/list', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error getting the list of files for the sequencing',
        description: res.data.Payload,
      });
    }
    console.log(res.data);
    return res.data.Payload;
  });
}

/**
 * Updates an organization in RareCare
 * @param Organization
 */
// export async function updateOrganization(Organization: RareCare.Organization) {
//   console.log(Organization);
//   return axios.post(ADMIN_API_ENDPOINT + '/admin/org/update', Organization).then((res) => {
//     if (res.data.Error) {
//       notification.error({
//         message: 'Error Updating Organization',
//         description: res.data.Payload,
//       });
//     } else {
//       notification.success({
//         message: 'Organization Updated Successfully',
//       });
//     }
//   });
// }
