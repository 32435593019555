import { ChakraProvider } from "@chakra-ui/react";
import { ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import en_US from "antd/es/locale/en_US";
import { AuthProvider, RequireAuth } from "../src/contexts/auth.context";
import Login from "../src/pages/Login";
import UserManagement from "./pages/CtmManagement";
import "./App.css";
import ForgotPasswordForm from "../src/pages/PasswordReset";
import ErrorPage from "../src/pages/ErrorPage";
import OrganizationsManagement from "../src/pages/OrganizationsManagement";
import { GlobalStateProvider } from "./contexts/globalState.context";
import Cases from "./pages/Cases";
import Sequencing from "./pages/Sequencing";
import PatientsManagement from "./pages/PatientsManagement";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/password-reset",
    element: <ForgotPasswordForm />,
  },
  {
    path: "/cases",
    element: (
      <RequireAuth>
        <Cases />
      </RequireAuth>
    ),
  },
  {
    path: "/organizations",
    element: (
      <RequireAuth>
        <OrganizationsManagement />
      </RequireAuth>
    ),
  },
  {
    path: "/care-team-members",
    element: (
      <RequireAuth>
        <UserManagement />
      </RequireAuth>
    ),
  },
  {
    path: "/patients",
    element: (
      <RequireAuth>
        <PatientsManagement />
      </RequireAuth>
    ),
  },
  {
    path: "/sequencing",
    element: (
      <RequireAuth>
        <Sequencing />
      </RequireAuth>
    ),
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <ConfigProvider locale={en_US}>
            <AuthProvider>
              <GlobalStateProvider>
                <RouterProvider router={router} />
              </GlobalStateProvider>
            </AuthProvider>
          </ConfigProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
