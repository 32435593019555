import Layout from 'src/components/Layout';
import { useGlobalState } from '../contexts/globalState.context';
import AdminsTable from '../components/Admins/AdminsTable';
import OrganizationsTable from '../components/Organizations/OrganizationsTable';
import { PageContainer } from '@ant-design/pro-components';
import { Col, Row } from 'antd';
import React from 'react';

const OrganizationsManagement: React.FC = () => {
  const { isGlobalAdmin } = useGlobalState();
  const globalAdmin = isGlobalAdmin();

  return (
    <Layout title="Organizations management">
      <PageContainer>
        {globalAdmin && (
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <OrganizationsTable />
            </Col>
          </Row>
        )}

        <Row gutter={[0, 16]}>
          <Col span={24}>
            <AdminsTable />
          </Col>
        </Row>
      </PageContainer>
    </Layout>
  );
};

export default OrganizationsManagement;
