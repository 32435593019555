import {
  addOrganization,
  getOrganizationMembers,
  updateOrganization,
} from '../../../API/Organizations';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, List, Row, Space } from 'antd';
import React, { useState } from 'react';

type Props = {
  refresh: () => void;
  buttonTitle: string;
  buttonType?: string;
  editValues?: RareCare.Organization;
};

const OrganizationDrawer: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  if (!loaded) {
    if (props.editValues?.OrganizationID !== undefined) {
      getOrganizationMembers(props.editValues.OrganizationID).then((res) => {
        setMembers(res);
        setLoaded(true);
      });
    }
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = () => {
    if (props.buttonType === 'edit') {
      const org = props.editValues as RareCare.Organization;
      org.OrganizationName = form.getFieldValue('OrganizationName');
      updateOrganization(org).then(() => {
        props.refresh();
        setOpen(false);
      });
    } else {
      addOrganization(form.getFieldValue('OrganizationName')).then(() => {
        props.refresh();
        setOpen(false);
      });
    }
  };

  const renderButton = () => {
    if (props.buttonType === 'edit') {
      return <a onClick={showDrawer}>Edit</a>;
    } else {
      return (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          {props.buttonTitle}
        </Button>
      );
    }
  };

  return (
    <>
      {renderButton()}

      <Drawer
        title={props.buttonTitle}
        width={512}
        onClose={onClose}
        visible={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark form={form} initialValues={props.editValues}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="OrganizationName"
                label="Organization Name"
                rules={[{ required: true, message: 'Please enter a name for the organization' }]}
              >
                <Input placeholder="Organization Inc." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={16}>
          <Col span={24}>
            Members:
            <List
              itemLayout="horizontal"
              dataSource={members}
              renderItem={(item: any) => (
                <List.Item>
                  <List.Item.Meta
                    // title={<a href="https://ant.design">{item.UserAttributes.family_name}</a>}
                    title={item.UserAttributes[2].Value + ' ' + item.UserAttributes[3].Value}
                    // description={item.UserAttributes[2].Name}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default OrganizationDrawer;
