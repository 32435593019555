import { addSequencingProvider } from '../../API/Sequencing';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import React, { useState } from 'react';

type Props = {
  refresh: () => void;
  buttonTitle: string;
  buttonType?: string;
  editValues?: RareCare.SequencingProvider;
};

const SequencingProviderDrawer: React.FC<Props> = (props) => {
  // const [loaded, setLoaded] = useState(false);
  // const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  // if (!loaded) {
  //   if (props.editValues != undefined) {
  //     getOrganizationMembers(props.editValues.OrganizationID).then((res) => {
  //       console.log(res);
  //       setMembers(res);
  //       setLoaded(true);
  //     });
  //   }
  // }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = () => {
    if (props.buttonType === 'edit') {
      // const org = props.editValues as RareCare.Organization;
      // org.OrganizationName = form.getFieldValue('OrganizationName');
      // updateOrganization(org).then(() => {
      //   props.refresh();
      //   setOpen(false);
      // });
    } else {
      addSequencingProvider(form.getFieldValue('SequencingProviderName')).then(() => {
        props.refresh();
        setOpen(false);
      });
    }
  };

  const renderButton = () => {
    if (props.buttonType === 'edit') {
      return <a onClick={showDrawer}>Edit</a>;
    } else {
      return (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          {props.buttonTitle}
        </Button>
      );
    }
  };

  return (
    <>
      {renderButton()}

      <Drawer
        title={props.buttonTitle}
        width={512}
        onClose={onClose}
        visible={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark form={form} initialValues={props.editValues}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="SequencingProviderName"
                label="Sequencing Provider Name"
                rules={[{ required: true, message: 'Please enter a name for the provider' }]}
              >
                <Input placeholder="Sequencing Inc." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default SequencingProviderDrawer;
