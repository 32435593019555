import { adminUpdatePassword, updateUserPassword } from '../../API/Admins';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import React, { useState } from 'react';

type Props = {
  userID: string;
  type: string;
};

const PasswordDrawer: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = () => {
    if (props.type === 'admin') {
      adminUpdatePassword(props.userID, form.getFieldValue('password')).then(() => {
        setOpen(false);
      });
    }
    if (props.type === 'user') {
      updateUserPassword(props.userID, form.getFieldValue('password')).then(() => {
        setOpen(false);
      });
    }
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <>
      <a onClick={showDrawer}>Reset Password</a>
      <Drawer
        title={'Password Reset'}
        // width={1024}
        size={'large'}
        onClose={onClose}
        visible={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" requiredMark={true} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="New Password"
                rules={[{ required: true, message: 'Please enter a new password' }]}
              >
                <Input type={'password'} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default PasswordDrawer;
