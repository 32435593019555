import React, { useRef, useState } from "react";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import Layout from "../components/Layout";
import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  HStack,
  Heading,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { downloadCTMFile, getCareTeamMemberList } from "src/API/careTeamMember";
import CreateCareTeamForm from "src/components/CreateCareTeamForm";
import { FiDownload } from "react-icons/fi";

const handleDownloadFile = async (ctm: any, consentFile: any) => {
  try {
    const file = await downloadCTMFile({
      careTeamMemberId: ctm.id,
      fileName: consentFile.name,
    });
    const blob = new Blob([file], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (error) {
    console.error("Error:", error);
  }
};
const CtmManagement: React.FC = () => {
  const [ctmList, setCtmList] = useState<any>([]);
  const [filteredList, setFilteredList] = useState<any>([]);

  const actionRef = useRef<ActionType>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const refetchCtms = async () => {
    const data = await getCareTeamMemberList();
    setCtmList(data);
  };

  const columns: ProColumns<any>[] = [
    {
      title: "First Name",
      dataIndex: "name",
    },
    {
      title: "Last Name",
      dataIndex: "familyName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Consent Agreed",
      render: (ctm: any) => {
        const value = ctm.consentAgreed;
        const consentFile = ctm.files?.find((file: any) => file.fileType === "consent_form");
        return value ? (
          <HStack>
            <span>Yes</span>
            {consentFile && (
              <Tooltip label="Download consent form">
                <IconButton
                  aria-label="Download consent form"
                  icon={<FiDownload />}
                  variant={"ghost"}
                  onClick={() => handleDownloadFile(ctm, consentFile)}
                />
              </Tooltip>
            )}
          </HStack>
        ) : (
          <span>No</span>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "title",
      render: (value: string) => {
        const role = value.replace("-", "");
        return <span>{role ? role : "N/A"}</span>;
      },
    },
    {
      title: "Specialty",
      dataIndex: "specialty",
      render: (value: string) => {
        const specialty = value.replace("-", "");
        return <span>{specialty ? specialty : "N/A"}</span>;
      },
    },
    {
      title: "Medical Affiliation",
      dataIndex: "medicalAffiliation",
      render: (value: string) => {
        const affiliation = value.replace("-", "");
        return <span>{affiliation ? affiliation : "N/A"}</span>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      render: (value: string) => {
        const phone = value.replace("-", "");
        return <span>{phone ? phone : "N/A"}</span>;
      },
    },
  ];

  return (
    <Layout title="Care Team Members management">
      <PageContainer>
        <ProTable<any>
          headerTitle="RareCare CTMs"
          actionRef={actionRef}
          rowKey="email"
          locale={{}}
          columns={columns}
          request={async (params) => {
            let data = ctmList;

            if (!data.length) {
              data = await getCareTeamMemberList();
              setCtmList(data);
            }

            if (Object.keys(params).length > 2) {
              const filterKeys = ["name", "familyName", "email"];
              const filters = [];

              for (const key of filterKeys) {
                if (params[key]) filters.push([key, params[key]]);
              }

              const filteredData = data.filter((item: any) => {
                return filters.every(([key, value]) => {
                  return item[key]?.toLowerCase().includes(value.toLowerCase());
                });
              });

              setFilteredList(filteredData);
            } else {
              setFilteredList(data);
            }

            return null;
          }}
          dataSource={filteredList}
          search={{
            labelWidth: 120,
          }}
          toolBarRender={() => [
            <Button onClick={onOpen} colorScheme="blue">
              Add Care Team Member
            </Button>,
          ]}
        />
      </PageContainer>

      <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
        <DrawerContent>
          <DrawerHeader>
            <Heading as="h3" fontSize="20px" color="brand.gray1">
              Add Care Team Member
            </Heading>
          </DrawerHeader>
          <DrawerCloseButton />
          <CreateCareTeamForm
            onSuccess={() => {
              refetchCtms();
              onClose();
            }}
          />
        </DrawerContent>
      </Drawer>
    </Layout>
  );
};

export default CtmManagement;
