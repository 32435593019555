import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_ADMIN_API_ENDPOINT;

export async function getCareTeamMemberList() {
  return axios.get(API_BASE_URL + "/care-team-members").then((res) => {
    return res.data;
  });
}

export function inviteCareTeamMember({ patientId, careTeamMemberId }: any) {
  return axios.post(API_BASE_URL + `/care-team-members/invite`, {
    patientId,
    careTeamMemberId,
  });
}

export async function registerCareTeamMember(data: any) {
  const endpoint = API_BASE_URL + "/care-team-members/register";
  return axios.post(endpoint, data).then((res) => res.data);
}

export async function downloadCTMFile({ fileName, careTeamMemberId }) {
  return axios
    .post(
      API_BASE_URL + "/care-team-members/download-file",
      { careTeamMemberId, fileName },
      { responseType: "arraybuffer" }
    )
    .then((res: any) => {
      return res.data;
    });
}

export async function searchCareTeamMembers(query: string) {
  try {
    const endpoint = API_BASE_URL + `/care-team-members/search?query=${encodeURIComponent(query)}`;
    const { data }: any = await axios.get(endpoint);
    return data || [];
  } catch (error: any) {
    console.error(error);
    return { error, message: error.message, data: [] };
  }
}

export async function sendCareTeamMemberInviteEmail({ email, patientId }: any) {
  try {
    const endpoint = API_BASE_URL + `/care-team-members/invite-by-email`;
    const { data }: any = await axios.post(endpoint, { email, patientId });
    return data;
  } catch (error: any) {
    console.error(error);
    return { error, message: error.message, data: null };
  }
}
