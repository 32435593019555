import { notification } from 'antd';
import axios from 'axios';

const ADMIN_API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;

export async function getCase(caseID: string) {
  const params = {
    CaseID: caseID,
  };
  return axios.post(ADMIN_API_ENDPOINT + '/case/get', params).then((res) => {
    console.log(res);

    if (res.data.Error) {
      notification.error({
        message: 'Error Getting Case',
        description: res.data.Payload,
      });
    }
    return res.data;
  });
}

export async function uploadCaseFile(caseID: string, filename: string, data: string) {
  const params = {
    CaseID: caseID,
    Filename: filename,
    B64Data: data,
  };
  return axios.post(ADMIN_API_ENDPOINT + '/case/files/upload', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Uploading Sequencing Result',
        description: res.data.Payload,
      });
    } else {
      notification.success({
        message: 'Sequencing Result Uploaded Successfully',
      });
    }
  });
}

export async function downloadFile(CaseID: string, Filename: string): Promise<any> {
  return axios
    .post(ADMIN_API_ENDPOINT + '/case/files/download', {
      CaseID: CaseID,
      Filename: Filename,
    })
    .then((res) => {
      return Buffer.from(res.data.Payload, 'base64');
    });
}

export async function updateCaseDetails(
  CaseID: string,
  Age: string,
  Gender: string,
  Tag: string,
  Ethnicity: string,
  MainDiagnosis: string,
) {
  const caseDetails = {
    CaseID: CaseID,
    Age: Age,
    Gender: Gender,
    Tag: Tag,
    Ethnicity: Ethnicity,
    MainDiagnosis: MainDiagnosis,
  };

  return axios
    .post(ADMIN_API_ENDPOINT + '/rarecare/case/updatedetails', caseDetails)
    .then((res) => {
      if (res.data.Error) {
        notification.error({
          message: 'Error Updating Case Details',
          description: res.data.Payload,
        });
      } else {
        notification.success({
          message: 'Case Details Updated Successfully',
        });
      }
    });
}
