import { APIPOSTRequest } from '../API/API';
import { notification } from 'antd';
import axios from 'axios';

const ADMIN_API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;

/**
 * Lists all the organizations in RareCare
 *
 * @returns {Promise} Promise object represents the list of organizations
 * @throws {Error} If the request fails
 *
 */
export async function listOrganizations(): Promise<RareCare.Organization[]> {
  return axios.get(ADMIN_API_ENDPOINT + '/admin/org/list').then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Retrieving Organizations List',
        description: res.data.Payload,
      });
      return [];
    }
    return res.data.Payload;
  });
}

/**
 * Creates a new organization in RareCare
 * @param orgName
 */
export async function addOrganization(orgName: string) {
  return axios
    .post(ADMIN_API_ENDPOINT + '/admin/org/new', { OrganizationName: orgName })
    .then((res) => {
      if (res.data.Error) {
        notification.error({
          message: 'Error Creating New Organization',
          description: res.data.Payload,
        });
      } else {
        notification.success({
          message: 'Organization Added Successfully',
        });
      }
    });
}

export async function listOrganizationCases(orgID: string) {
  const params = {
    OrganizationID: orgID,
  };
  return await APIPOSTRequest('/admin/org/list/cases', params);
}

/**
 * Updates an organization in RareCare
 * @param Organization
 */
export async function updateOrganization(Organization: RareCare.Organization) {
  console.log(Organization);
  return axios.post(ADMIN_API_ENDPOINT + '/admin/org/update', Organization).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error Updating Organization',
        description: res.data.Payload,
      });
    } else {
      notification.success({
        message: 'Organization Updated Successfully',
      });
    }
  });
}

export async function getOrganizationMembers(OrganizationID: string) {
  return axios
    .post(ADMIN_API_ENDPOINT + '/admin/org/list/members', { OrganizationID: OrganizationID })
    .then((res) => {
      if (res.data.Error) {
        notification.error({
          message: 'Error Getting Organization Members',
          description: res.data.Payload,
        });
        return res.data.Payload;
      } else {
        return res.data.Payload;
      }
    });
  // .catch((err) => {
  //   notification.error({
  //     message: 'Error Getting Organization Members',
  //     description: err,
  //   });
  // })
}
