import { APIPOSTRequest } from './API';
import { notification } from 'antd';
import axios from 'axios';

const ADMIN_API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;

export async function adminGetAdminsList() {
  return axios.get(ADMIN_API_ENDPOINT + '/admin/admins/list').then((res) => {
    const users: any = [];
    res.data.Payload.map((user: any) => {
      user.Attributes.map((attr: any) => {
        user[attr.Name] = attr.Value;
      });
      user.ID = user.Username;
      user.key = user.Username;
      users.push(user);
    });
    return users;
  });
}

export async function adminAddAdmin(
  name: string,
  familyName: string,
  email: string,
  password: string,
  organization: string,
) {
  const params = {
    Name: name,
    FamilyName: familyName,
    Email: email,
    Organization: organization,
    Password: password,
  };

  return axios.post(ADMIN_API_ENDPOINT + '/admin/admins/new', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error' + res.data.Payload,
      });
    } else {
      notification.success({
        message: 'Admin Added Successfully',
      });
    }
  });
}

export async function adminUpdateAdmin(
  ID: string,
  name: string,
  familyName: string,
  email: string,
  organization: string,
) {
  const params = {
    ID: ID,
    Name: name,
    FamilyName: familyName,
    Email: email,
    Organization: organization,
  };

  return axios.post(ADMIN_API_ENDPOINT + '/admin/admins/update', params).then((res) => {
    if (res.data.Error) {
      notification.error({
        message: 'Error' + res.data.Payload,
      });
    }
    return res.data.Payload;
  });
}

export async function adminGetAdmin(userID: string) {
  const params = {
    UserID: userID,
  };

  return await APIPOSTRequest('/admin/admins/get', params);
}

// Changes the password of an Administrator
export async function adminUpdatePassword(userID: string, password: string) {
  const params = {
    UserID: userID,
    Password: password,
  };
  return await APIPOSTRequest(
    '/admin/admins/update/password',
    params,
    'Password Updated Successfully',
  );
}

//Changes the password of a User
export async function updateUserPassword(userID: string, password: string) {
  const params = {
    UserID: userID,
    Password: password,
  };
  return await APIPOSTRequest(
    '/admin/user/update/password',
    params,
    'Password Updated Successfully',
  );
}
